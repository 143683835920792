/* eslint-disable prettier/prettier */
import { FACTORY_ADDRESS as V2_FACTORY_ADDRESS } from '@uniswap/v2-sdk'
import { FACTORY_ADDRESS as V3_FACTORY_ADDRESS } from '@uniswap/v3-sdk'
import { SupportedChainId } from 'constants/chains'

import { constructSameAddressMap } from '../utils/constructSameAddressMap'

type AddressMap = { [chainId: number]: string }

export const UNI_ADDRESS: AddressMap = constructSameAddressMap('0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984')

export const UNISWAP_NFT_AIRDROP_CLAIM_ADDRESS = '0x8B799381ac40b838BBA4131ffB26197C432AFe78'

export const V2_FACTORY_ADDRESSES: AddressMap = constructSameAddressMap(V2_FACTORY_ADDRESS)
export const V2_ROUTER_ADDRESS: AddressMap = constructSameAddressMap('0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D')


// avalanche v3 addresses
const AVALANCHE_V3_CORE_FACTORY_ADDRESSES = '0x25624eCAbfe4918F70F4cFBd989C4FdDD70e7CC6'
const AVALANCHE_V3_MIGRATOR_ADDRESSES = '0xF340BDea642021CC6cA751830ef3094038A2d603'
const AVALANCHE_MULTICALL_ADDRESS = '0xE3166A9156cd3C9043066ECe11789C9808b02b34'
const AVALANCHE_QUOTER_ADDRESSES = '0x799ca37F8B5D8761ca4a12a47448C0420FfCd97c'
const AVALANCHE_NONFUNGIBLE_POSITION_MANAGER_ADDRESSES = '0x20D913A22bf1186301367B0644271a9BEa53D192'
const AVALANCHE_TICK_LENS_ADDRESSES = '0x96E276e824F78233df95D480704A12F8e9f532a3'
const AVALANCHE_ROUTER_ADDRESSES = '0x787Bd62B389daD20cFf3AE861DbB5d5b7a0012D7'


// fuji avalanche v3 addresses
const AVALANCHE_FUJI_V3_CORE_FACTORY_ADDRESSES = '0x1Fa488afcfA004E19B469217485F3F101630c0EC'
const AVALANCHE_FUJI_V3_MIGRATOR_ADDRESSES = '0xDC13bd834bA376234AAe89A1a8986e9CeBD1e935'
const AVALANCHE_FUJI_MULTICALL_ADDRESS = '0x40976897eCcFdD92316ACDfA1FFa0598008eBa6c'
const AVALANCHE_FUJI_QUOTER_ADDRESSES = '0xF78bd03e610b99f9866E696c45C9C401A4f09f86'
const AVALANCHE_FUJI_NONFUNGIBLE_POSITION_MANAGER_ADDRESSES = '0x8675baf0c873d3e5F0141d5dEC45d354C30f435F'
const AVALANCHE_FUJI_TICK_LENS_ADDRESSES = '0x7c0871C3aefd7fcb5712Ed83131711aDc612B31f'
const AVALANCHE_FUJI_ROUTER_ADDRESSES = '0x66E10904D143d866aE57c7C18D534028B4066B88'

/* V3 Contract Addresses */
export const V3_CORE_FACTORY_ADDRESSES: AddressMap = {
  [SupportedChainId.AVALANCHE]: AVALANCHE_V3_CORE_FACTORY_ADDRESSES,
  [SupportedChainId.AVALANCHE_FUJI]: AVALANCHE_FUJI_V3_CORE_FACTORY_ADDRESSES,
}

export const V3_MIGRATOR_ADDRESSES: AddressMap = {
  [SupportedChainId.AVALANCHE]: AVALANCHE_V3_MIGRATOR_ADDRESSES,
  [SupportedChainId.AVALANCHE_FUJI]: AVALANCHE_FUJI_V3_MIGRATOR_ADDRESSES,
}

export const MULTICALL_ADDRESS: AddressMap = {
  [SupportedChainId.AVALANCHE]: AVALANCHE_MULTICALL_ADDRESS,
  [SupportedChainId.AVALANCHE_FUJI]: AVALANCHE_FUJI_MULTICALL_ADDRESS,
}

/**
 * The oldest V0 governance address
 */
export const GOVERNANCE_ALPHA_V0_ADDRESSES: AddressMap = constructSameAddressMap(
  '0x5e4be8Bc9637f0EAA1A755019e06A68ce081D58F'
)
/**
 * The older V1 governance address
 */
export const GOVERNANCE_ALPHA_V1_ADDRESSES: AddressMap = {
  [SupportedChainId.AVALANCHE]: '0xC4e172459f1E7939D522503B81AFAaC1014CE6F6',
}
/**
 * The latest governor bravo that is currently admin of timelock
 */
export const GOVERNANCE_BRAVO_ADDRESSES: AddressMap = {
  [SupportedChainId.AVALANCHE]: '0x408ED6354d4973f66138C91495F2f2FCbd8724C3',
}

export const TIMELOCK_ADDRESS: AddressMap = constructSameAddressMap('0x1a9C8182C09F50C8318d769245beA52c32BE35BC')

export const MERKLE_DISTRIBUTOR_ADDRESS: AddressMap = {
  [SupportedChainId.AVALANCHE]: '0x090D4613473dEE047c3f2706764f49E0821D256e',
}

export const ARGENT_WALLET_DETECTOR_ADDRESS: AddressMap = {
  [SupportedChainId.AVALANCHE]: '',
}

export const QUOTER_ADDRESSES: AddressMap = {
  [SupportedChainId.AVALANCHE]: AVALANCHE_QUOTER_ADDRESSES,
  [SupportedChainId.AVALANCHE_FUJI]: AVALANCHE_FUJI_QUOTER_ADDRESSES,
}

export const NONFUNGIBLE_POSITION_MANAGER_ADDRESSES: AddressMap = {
  [SupportedChainId.AVALANCHE]: AVALANCHE_NONFUNGIBLE_POSITION_MANAGER_ADDRESSES,
  [SupportedChainId.AVALANCHE_FUJI]: AVALANCHE_FUJI_NONFUNGIBLE_POSITION_MANAGER_ADDRESSES,
}

export const ENS_REGISTRAR_ADDRESSES: AddressMap = {

}

export const SOCKS_CONTROLLER_ADDRESSES: AddressMap = {
  [SupportedChainId.AVALANCHE]: '0x65770b5283117639760beA3F867b69b3697a91dd',
}

export const TICK_LENS_ADDRESSES: AddressMap = {
  [SupportedChainId.AVALANCHE]: AVALANCHE_TICK_LENS_ADDRESSES,
  [SupportedChainId.AVALANCHE_FUJI]: AVALANCHE_FUJI_TICK_LENS_ADDRESSES,
}
